<template>
  <div>
    <PageHeader :items="items" :optionalItems="optionalItems">
      <template #filters>
        <div class="search-filters">
          <div class="page-header-search">
            <input
              autocomplete="off"
              type="text"
              name="search"
              :placeholder="$t('Search…')"
              v-model="search_string"
            />
            <button class="" type="button" @click="search_string = ''">
              {{ $t("Reset") }}
            </button>
          </div>
        </div>
      </template>
    </PageHeader>
    <div class="custom-search d-flex justify-content-between">
      <b-form-group>
        <div class="d-flex align-items-center">
          <span class="text-nowrap"> {{ $t("Rows per page") }} </span>
          <b-form-select
            v-model="pageLength"
            :options="['25', '50', '100']"
            class="ml-1"
            @input="(value) => onPerPageChange({ pageLength: value })"
          />
        </div>
      </b-form-group>
    </div>

    <!-- table -->
    <vue-good-table
      styleClass="vgt-table striped"
      :columns="columns"
      :fixed-header="true"
      :rows="modifiedUsers"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm,
        trigger: 'enter',
      }"
      :pagination-options="{
        enabled: true,
        perPage: pageLength,
      }"
      mode="remote"
      @on-page-change="onPageChange"
      @on-sort-change="onSortChange"
      @on-per-page-change="onPerPageChange"
    >
      <template slot="table-row" slot-scope="props">
        <!-- Column: Name -->
        <div v-if="props.column.field === 'name'" class="px-auto">
          <span>{{ props.row.first_name + " " + props.row.last_name }}</span>
        </div>
        <span v-else-if="props.column.field === 'creationDate'">
          {{ props.row.creation_date.date }}
        </span>
        <span v-else-if="props.column.field === 'roles'">
          <multiselect
            v-model="props.row.roles"
            label="title"
            @select="assignRoles($event, props.row.roles, props.row.id)"
            @remove="removeRoles($event, props.row.roles, props.row.id)"
            track-by="id"
            :options="roles"
            :multiple="true"
          >
          </multiselect>
        </span>

        <!-- Column: Action -->
        <span v-else-if="props.column.field === 'action'">
          <feather-icon
            v-if="$can(`${$route.meta.permission}.edit`)"
            @click="$router.push(`/users/${props.row.id}/edit`)"
            size="16"
            icon="Edit2Icon"
            class="mr-50"
          />
          <feather-icon
            v-if="$can(`${$route.meta.permission}.delete`)"
            @click="destroy(props.row.id)"
            size="16"
            icon="TrashIcon"
            class="mr-50"
          />
        </span>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->
      <template slot="pagination-bottom" slot-scope="props">
        <div class="d-flex justify-content-end flex-wrap">
          <div>
            <b-pagination
              :value="1"
              :total-rows="totalRecords"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @input="(value) => onPageChange({ currentPage: value })"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
  </div>
</template>

<script>
import PageHeader from "@/components/PageHeader.vue";
import { debounce } from "@/utils/debounce";
import { mapGetters } from "vuex";
import Multiselect from "vue-multiselect";

export default {
  components: {
    PageHeader,
    Multiselect,
  },
  computed: {
    ...mapGetters("roles", ["roles"]),
    modifiedUsers() {
      return this.rows.map((row) => {
        return {
          ...row,
          roles: row.roles
            .map((roleId) => {
              return {
                ...(this.roles?.find((role) => role.id == roleId) ?? {}),
              };
            })
            .filter((role) => role.id),
        };
      });
    },

    columns() {
      return [
        {
          tdClass: "vgt-center-align",
          thClass: "vgt-center-align white-color",
          label: this.$t("Name"),
          field: "name",
          sortable: false,
        },
        {
          tdClass: "vgt-center-align",
          thClass: "vgt-center-align white-color",
          label: this.$t("Email"),
          field: "email",
          sortable: false,
        },
        {
          tdClass: "vgt-center-align",
          thClass: "vgt-center-align white-color",
          label: this.$t("City"),
          field: "city",
          sortable: false,
        },
        {
          tdClass: "vgt-center-align",
          thClass: "vgt-center-align white-color",
          label: this.$t("Street"),
          field: "street",
          sortable: false,
        },
        {
          tdClass: "vgt-center-align",
          thClass: "vgt-center-align white-color",
          label: this.$t("Zip"),
          field: "zip",
          sortable: false,
        },
        {
          tdClass: "vgt-center-align",
          thClass: "vgt-center-align white-color",
          label: this.$t("Roles"),
          field: "roles",
          sortable: false,
        },
        {
          tdClass: "vgt-center-align",
          thClass: "vgt-center-align white-color",
          label: this.$t("Action"),
          field: "action",
          sortable: false,
        },
      ];
    },
    items() {
      return [
        {
          text: this.$t("Dental Twin"),
          to: "/home",
        },
        {
          text: this.$t("Settings"),
          to: "/settings",
        },
        {
          text: this.$t("Users"),
          active: true,
        },
      ];
    },
    optionalItems() {
      return {
        createBtn1: {
          show: true,
          text: this.$t("Create User"),
          icon: "PlusIcon",
          path: "/users/create",
          permission: "user.create",
        },
      };
    },
  },
  data() {
    return {
      totalRecords: 0,
      pageLength: 50,
      page: 1,
      rows: [],
      searchTerm: "",
      search_string: "",
    };
  },
  async created() {
    this.debouncedFetch = debounce(async (newValue, oldValue) => {
      try {
        await this.loadItems();
      } catch (e) {
        console.error(e);
      }
    }, 300);
    this.loadItems();
  },
  watch: {
    search_string(...val) {
      this.debouncedFetch();
    },
  },

  async mounted() {
    try {
      await this.$store.dispatch("roles/list", {
        limit_start: 0,
        limit_count: 100,
      });
      await this.loadItems();
    } catch (e) {
      console.error("api error ___", e);
    }
  },

  methods: {
    truncateString(str, maxLength) {
      if (str.length > maxLength) {
        return str.substring(0, maxLength) + "...";
      } else {
        return str;
      }
    },
    updateParams(newProps) {
      this.serverParams = Object.assign({}, this, newProps);
    },

    onPageChange(params) {
      this.page = params.currentPage;
      console.log(this.page);
      this.loadItems();
    },

    onPerPageChange(params) {
      this.updateParams({ pageLength: params.pageLength });
      this.loadItems();
    },
    async assignRoles(event, roles, id) {
      let arr = [];
      arr.push(event.id);
      roles.forEach((role) => {
        arr.push(role.id);
      });
      const payload = {
        roles: arr,
      };
      await this.$store.dispatch("users/update", {
        id: id,
        roles: payload.roles,
      });
    },
    async removeRoles(event, roles, id) {
      let arr = [];
      roles.forEach((role) => {
        arr.push(role.id);
      });
      arr.pop(event.id);
      const payload = {
        roles: arr,
      };
      await this.$store.dispatch("users/update", {
        id: id,
        roles: payload.roles,
      });
    },

    onSortChange(params) {
      this.sortType = params[0].type;
      this.sortField = params[0].field;
      this.loadItems();
    },

    // load items is what brings back the rows from server
    async loadItems() {
      try {
        this.$store.commit("showLoader", true);
        let response = await this.$store.dispatch("users/list", {
          limit_start: this.pageLength * (this.page - 1),
          limit_count: this.pageLength,
          search_string: this.search_string,
        });
        this.rows = response?.data?.data;
        this.totalRecords = response?.data?.count;
      } catch (e) {
        console.error("api error ___", e);
      } finally {
        this.$store.commit("showLoader", false);
      }
    },
    async destroy(id) {
      this.$swal({
        title: this.$t("Do you want to delete this record?"),
        text: this.$t("You can't revert your action"),
        type: "warning",
        customClass: "custom-delete-popup",
        showCancelButton: true,
        confirmButtonText: this.$t("Yes delete it!"),
        cancelButtonText: this.$t("No"),
        showCloseButton: true,
        showLoaderOnConfirm: true,
      }).then(async (result) => {
        if (result.isConfirmed === true) {
          await this.$store.dispatch("users/destroy", {
            id: id,
          });
          this.loadItems();
        }
      });
    },
  },
};
</script>

<style>
#nprogress {
  position: relative;
  z-index: 9999999;
}

.white-color {
  color: white !important;
}

.vgt-responsive {
  overflow-x: visible !important;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
